import AuthApi from "./authApi";

// Staff
import StaffContactApi from "./staff/contactStaff";
import StaffNursingApi from "./staff/nursingStaff";
import BoardStaffApi from "./staff/boardStaffApi";
import PatientStaffApi from "./staff/patientStaffApi";
import HSStaffApi from "./staff/hsStaffApi";
import RoutineApi from "./staff/routineApi";
import NoticeStaffApi from "./staff/noticeStaffApi";

// Staff
import StaffDashboard from "./staff/dashboard";
import StaffNursingCareRecord from "./staff/nursingCareRecord";

// Home system - Admin
import HSNoticeApi from "./home-system/admin/hsNotice";
import HSRoomApi from "./home-system/admin/hsRoom";
import HSPatientApi from "./home-system/admin/hsPatient";
import HSNursingApi from "./home-system/admin/hsNursing";
import HSDashboardApi from "./home-system/admin/hsDashboard";

// Home system - Staff
import HSStaffNoticeApi from "./home-system/staff/hsNotice";
import HSStaffRoomApi from "./home-system/staff/hsRoom";
import HSStaffPatientApi from "./home-system/staff/hsPatient";
import HSStaffNursingApi from "./home-system/staff/hsNursing";
import HSStaffDashboardApi from "./home-system/staff/hsDashboard";

// Admin
import AdminDashboard from "./admin/dashboard";
import AdminEvalueOne from "./admin/evalueOne";
import HSAdminApi from "./admin/homeSystemApi";
import BoardAdminApi from "./admin/boardAdmin";
import PatientAdminApi from "./admin/patientAdmin";
import ContactApi from "./admin/contactAdmin";
import NursingApi from "./admin/nursingAdmin";
import FacilityRegistrationApi from "./admin/facilityRegistration";
import CareRegistrationApi from "./admin/careRegistration";
import ProfileAdminApi from "./admin/profileAdmin";
import ShiftManagementApi from "./admin/shiftManagement";
import ShiftRegistrationApi from "./admin/shiftRegistration";
import AdminEvalueTwo from "./admin/evalueTwo";
import AdminShiftManagement from "./admin/shiftManagements";
import AdminNursingCareRecord from "./admin/nursingCareRecord";
import StaffEvalueOne from "./staff/evalueOne";
import StaffEvalueTwo from "./staff/evalueTwo";

const apiFactory = {
  authApi: new AuthApi(),
  careRegistrationApi: new CareRegistrationApi(),
  shiftRegistrationApi: new ShiftRegistrationApi(),
  profileAdminApi: new ProfileAdminApi(),
  shiftManagementApi: new ShiftManagementApi(),
  contactApi: new ContactApi(),
  nursingApi: new NursingApi(),
  facilityApi: new FacilityRegistrationApi(),
  staffContactApi: new StaffContactApi(),
  staffNursingApi: new StaffNursingApi(),
  boardAdminApi: new BoardAdminApi(),
  patientAdminApi: new PatientAdminApi(),
  boardStaffApi: new BoardStaffApi(),
  patientStaffApi: new PatientStaffApi(),
  staffRoutineApi: new RoutineApi(),
  staffNoticeApi: new NoticeStaffApi(),
  staffEvalueOneApi: new StaffEvalueOne(),
  staffEvalueTwoApi: new StaffEvalueTwo(),

  // Home system - Admin
  hsAdminApi: new HSAdminApi(),
  hsNoticeAdminApi: new HSNoticeApi(),
  hsRoomAdminApi: new HSRoomApi(),
  hsPatientAdminApi: new HSPatientApi(),
  hsNursingAdminApi: new HSNursingApi(),
  hsDashboardAdminApi: new HSDashboardApi(),

  // Home system - Staff
  hsStaffApi: new HSStaffApi(),
  hsNoticeStaffApi: new HSStaffNoticeApi(),
  hsRoomStaffApi: new HSStaffRoomApi(),
  hsPatientStaffApi: new HSStaffPatientApi(),
  hsNursingStaffApi: new HSStaffNursingApi(),
  hsDashboardStaffApi: new HSStaffDashboardApi(),

  // Admin
  adminEvalueOneApi: new AdminEvalueOne(),
  adminDashboardApi: new AdminDashboard(),
  adminEvalueTwoApi: new AdminEvalueTwo(),
  adminShiftManagement: new AdminShiftManagement(),
  adminNursingCareRecordApi: AdminNursingCareRecord,

  // Staff
  staffDashboard: new StaffDashboard(),
  staffNursingCareRecord: new StaffNursingCareRecord(),
};

export default apiFactory;
